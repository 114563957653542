import { IconProps } from "@chakra-ui/react";
import { cn } from "@utils/cn";

export const LightiningIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M2.1665 9.83328C2.04034 9.83371 1.91665 9.79833 1.8098 9.73126C1.70295 9.6642 1.61732 9.56818 1.56287 9.45438C1.50841 9.34059 1.48736 9.21367 1.50217 9.08838C1.51697 8.9631 1.56702 8.84459 1.6465 8.74661L8.2465 1.94661C8.29601 1.88947 8.36347 1.85085 8.43782 1.8371C8.51217 1.82335 8.58898 1.83529 8.65565 1.87095C8.72232 1.90661 8.77489 1.96388 8.80472 2.03335C8.83456 2.10283 8.83988 2.18038 8.81983 2.25328L7.53983 6.26661C7.50209 6.36763 7.48941 6.47629 7.50289 6.58328C7.51637 6.69028 7.55561 6.7924 7.61723 6.8809C7.67885 6.96939 7.76101 7.04162 7.85668 7.09139C7.95235 7.14115 8.05866 7.16696 8.1665 7.16661H12.8332C12.9593 7.16618 13.083 7.20156 13.1899 7.26863C13.2967 7.3357 13.3823 7.43171 13.4368 7.54551C13.4913 7.65931 13.5123 7.78622 13.4975 7.91151C13.4827 8.03679 13.4326 8.15531 13.3532 8.25328L6.75317 15.0533C6.70366 15.1104 6.63619 15.149 6.56184 15.1628C6.4875 15.1765 6.41068 15.1646 6.34401 15.1289C6.27734 15.0933 6.22478 15.036 6.19494 14.9665C6.16511 14.8971 6.15978 14.8195 6.17983 14.7466L7.45983 10.7333C7.49758 10.6323 7.51025 10.5236 7.49677 10.4166C7.48329 10.3096 7.44406 10.2075 7.38244 10.119C7.32082 10.0305 7.23865 9.95827 7.14298 9.90851C7.04731 9.85874 6.941 9.83293 6.83317 9.83328H2.1665Z"
        stroke="white"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
