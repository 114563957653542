import { IconProps } from "@chakra-ui/react";
import { cn } from "@utils/cn";

export const TimeIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        d="M7.1665 1.33325H9.83317"
        stroke="white"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 9.33325L10.5 7.33325"
        stroke="white"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.49984 14.6667C11.4454 14.6667 13.8332 12.2789 13.8332 9.33333C13.8332 6.38781 11.4454 4 8.49984 4C5.55432 4 3.1665 6.38781 3.1665 9.33333C3.1665 12.2789 5.55432 14.6667 8.49984 14.6667Z"
        stroke="white"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
