import { doesBrowserHaveGeolocation } from "@utils/doesBrowserHaveGeolocation";
import { useState } from "react";
import { useCustomerStore } from "store/customer.store";

export const useGeolocationData = () => {
  const [isError, setIsError] = useState(false);
  const setSearchMethod = useCustomerStore.use.setSearchMethod();
  const setBrowserLocation = useCustomerStore.use.setBrowserLocation();
  const setBrowserGeolocationAccepted =
    useCustomerStore.use.setBrowserGeolocationAccepted();

  const isBrowserGeolocationAccepted =
    useCustomerStore.use.isBrowserGeolocationAccepted();
  const browserLongitude = useCustomerStore.use.browserLongitude();
  const browserLatitude = useCustomerStore.use.browserLatitude();

  const setGeolocationUndefinedData = () => {
    setBrowserGeolocationAccepted(false);
    setBrowserLocation(undefined, undefined);
    setSearchMethod("zipcode");
  };

  const setGeolocationData = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setBrowserGeolocationAccepted(true);
        setBrowserLocation(coords.longitude, coords.latitude);
        setIsError(false);
      },
      (e) => {
        // User did not accept geolocation or we've failed to get their location
        setGeolocationUndefinedData();
        setIsError(true);
        console.log(e);
      }
    );
  };

  const fetchGeolocationData = () => {
    const isBrowserGeolocationSupported = doesBrowserHaveGeolocation();

    if (
      isBrowserGeolocationSupported &&
      navigator &&
      navigator.permissions &&
      navigator.permissions.query
    ) {
      navigator.permissions.query({ name: "geolocation" }).then(() => {
        setGeolocationData();
      });
    }
  };

  return {
    isError,
    isBrowserGeolocationAccepted,
    isBrowserGeolocationSupported: doesBrowserHaveGeolocation(),
    browserLongitude,
    browserLatitude,
    fetchGeolocationData,
  };
};
